
export default {
  name: 'NowIcon',
  props: {
    name: {
      type: String,
      validator: val => [
        'academic-cap',
        'adjustments-horizontal',
        'align-center',
        'align-justify',
        'align-left',
        'align-right',
        'arrow-deviation',
        'arrow-down',
        'arrow-down-right',
        'arrow-down-tray',
        'arrow-left',
        'arrow-long-left',
        'arrow-path',
        'arrow-right',
        'arrow-top-right-on-square',
        'arrow-turn-down-left',
        'arrow-uturn-left',
        'arrow-uturn-right',
        'arrow-up',
        'arrow-up-right',
        'arrows-pointing-out',
        'arrows-up-down',
        'book-open',
        'bars-two',
        'bars-3-bottom-left',
        'bell',
        'bell-alert',
        'bold',
        'bolt',
        'bolt-slash',
        'briefcase',
        'bring-forward',
        'bullet-point',
        'building-library',
        'building-office',
        'building-office-2',
        'building-storefront',
        'camera',
        'calculator',
        'calendar-days',
        'chat-bubble-bottom-center-text',
        'chart-bar',
        'chart-bar-square',
        'check',
        'check-badge',
        'check-circle',
        'chevron-double-left',
        'chevron-double-right',
        'chevron-down',
        'chevron-left',
        'chevron-right',
        'chevron-up-down',
        'clipboard',
        'clipboard-document',
        'clipboard-document-check',
        'clipboard-document-list',
        'clock',
        'clock-arrow',
        'cog-6-tooth',
        'cloud-arrow-down',
        'cloud-arrow-up',
        'computer-desktop',
        'cpu-chip',
        'credit-card',
        'cube',
        'document',
        'document-arrow-up',
        'document-check',
        'document-duplicate',
        'document-plus',
        'document-text',
        'envelope',
        'ellipsis-horizontal',
        'ellipsis-horizontal-circle',
        'ellipsis-vertical',
        'exclamation-circle',
        'exclamation-triangle',
        'eye',
        'eye-slash',
        'face-frown',
        'file-zipper',
        'fire',
        'flag',
        'folder',
        'folder-open',
        'folder-plus',
        'folder-minus',
        'font-color',
        'frequency',
        'funnel',
        'globe-alt',
        'globe-europe-africa',
        'grip-vertical',
        'hand-raised',
        'home',
        'home-modern',
        'information-circle',
        'instance-fill',
        'italic',
        'light-bulb',
        'link',
        'list-bullet',
        'list-ol',
        'list-ul',
        'lock-closed',
        'lock-open',
        'magnifying-glass',
        'map',
        'map-pin',
        'minus',
        'moon',
        'no-symbol',
        'paper-airplane',
        'paper-clip',
        'pencil-square',
        'percent-badge',
        'phone',
        'photo',
        'play',
        'plus',
        'plus-circle',
        'puzzle-piece',
        'question-mark-circle',
        'receipt-refund',
        'rectangle-stack',
        'rectangle-group',
        'scale',
        'scissors',
        'share',
        'shield-check',
        'shield-exclamation',
        'squares-2x2',
        'square-3-stack-3d',
        'star',
        'stop',
        'strike',
        'sun',
        'table-cells',
        'tag',
        'thumbtack',
        'trash',
        'tv',
        'underline',
        'user',
        'user-circle',
        'user-group',
        'users',
        'x-mark'
      ].includes(val),
      default: 'chevron-double-left'
    },
    classes: {
      type: String,
      default: null
    },
    size: {
      type: [String, Number],
      default: '6'
    },
    pixel: {
      type: [String, Number],
      default: null
    },
    fill: {
      type: String,
      default: 'none'
    },
    viewBox: {
      type: String,
      default: '0 0 24 24'
    },
    strokeWidth: {
      type: String,
      default: '1.5'
    },
    stroke: {
      type: String,
      default: 'currentColor'
    }
  },
  computed: {
    classList () {
      const classList = [
        `h-${this.size}`,
        `w-${this.size}`
      ]
      return classList.join(' ')
    }
  }
}
