import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _08c7ac8e = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _7cea1a00 = () => interopDefault(import('../pages/action/index.vue' /* webpackChunkName: "pages/action/index" */))
const _5c1a0a0e = () => interopDefault(import('../pages/archive/index.vue' /* webpackChunkName: "pages/archive/index" */))
const _c6b81bb2 = () => interopDefault(import('../pages/audit/index.vue' /* webpackChunkName: "pages/audit/index" */))
const _a03c5a66 = () => interopDefault(import('../pages/deviation/index.vue' /* webpackChunkName: "pages/deviation/index" */))
const _8bb9f996 = () => interopDefault(import('../pages/it/index.vue' /* webpackChunkName: "pages/it/index" */))
const _7c0bac35 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _8bccc8de = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _63297c6c = () => interopDefault(import('../pages/offline.vue' /* webpackChunkName: "pages/offline" */))
const _613aa125 = () => interopDefault(import('../pages/onboarding/index.vue' /* webpackChunkName: "pages/onboarding/index" */))
const _52dcab06 = () => interopDefault(import('../pages/organization/index.vue' /* webpackChunkName: "pages/organization/index" */))
const _694a2f6d = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _13fda47e = () => interopDefault(import('../pages/report/index.vue' /* webpackChunkName: "pages/report/index" */))
const _4d9b3419 = () => interopDefault(import('../pages/risk/index.vue' /* webpackChunkName: "pages/risk/index" */))
const _16f8b3f2 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _3d8e9a2e = () => interopDefault(import('../pages/styles.vue' /* webpackChunkName: "pages/styles" */))
const _08e725ce = () => interopDefault(import('../pages/suggestion/index.vue' /* webpackChunkName: "pages/suggestion/index" */))
const _c582d962 = () => interopDefault(import('../pages/task/index.vue' /* webpackChunkName: "pages/task/index" */))
const _ea44f6e4 = () => interopDefault(import('../pages/unit/index.vue' /* webpackChunkName: "pages/unit/index" */))
const _53124583 = () => interopDefault(import('../pages/verify/index.vue' /* webpackChunkName: "pages/verify/index" */))
const _5b2fe487 = () => interopDefault(import('../pages/account/organizations.vue' /* webpackChunkName: "pages/account/organizations" */))
const _32df2a04 = () => interopDefault(import('../pages/account/password.vue' /* webpackChunkName: "pages/account/password" */))
const _413f0c29 = () => interopDefault(import('../pages/account/security.vue' /* webpackChunkName: "pages/account/security" */))
const _44c2d167 = () => interopDefault(import('../pages/audit/plan/index.vue' /* webpackChunkName: "pages/audit/plan/index" */))
const _6d31eee6 = () => interopDefault(import('../pages/organization/billing.vue' /* webpackChunkName: "pages/organization/billing" */))
const _df3b14f4 = () => interopDefault(import('../pages/organization/companies.vue' /* webpackChunkName: "pages/organization/companies" */))
const _0d72d97e = () => interopDefault(import('../pages/organization/events.vue' /* webpackChunkName: "pages/organization/events" */))
const _1f37fcb9 = () => interopDefault(import('../pages/organization/groups.vue' /* webpackChunkName: "pages/organization/groups" */))
const _529798a8 = () => interopDefault(import('../pages/organization/job.vue' /* webpackChunkName: "pages/organization/job" */))
const _8987d2f8 = () => interopDefault(import('../pages/organization/members.vue' /* webpackChunkName: "pages/organization/members" */))
const _690bfc7c = () => interopDefault(import('../pages/organization/subscription.vue' /* webpackChunkName: "pages/organization/subscription" */))
const _ebe0e09a = () => interopDefault(import('../pages/password/activate/index.vue' /* webpackChunkName: "pages/password/activate/index" */))
const _5eef91ba = () => interopDefault(import('../pages/password/forgot/index.vue' /* webpackChunkName: "pages/password/forgot/index" */))
const _5e4a8865 = () => interopDefault(import('../pages/password/reset/index.vue' /* webpackChunkName: "pages/password/reset/index" */))
const _8e190802 = () => interopDefault(import('../pages/task/group/index.vue' /* webpackChunkName: "pages/task/group/index" */))
const _789ae42a = () => interopDefault(import('../pages/action/_id/index.vue' /* webpackChunkName: "pages/action/_id/index" */))
const _5e59a192 = () => interopDefault(import('../pages/audit/_id/index.vue' /* webpackChunkName: "pages/audit/_id/index" */))
const _2e6a8190 = () => interopDefault(import('../pages/deviation/_id/index.vue' /* webpackChunkName: "pages/deviation/_id/index" */))
const _d6a508c0 = () => interopDefault(import('../pages/it/_id/index.vue' /* webpackChunkName: "pages/it/_id/index" */))
const _52545069 = () => interopDefault(import('../pages/report/_id/index.vue' /* webpackChunkName: "pages/report/_id/index" */))
const _5d1a9a84 = () => interopDefault(import('../pages/risk/_id/index.vue' /* webpackChunkName: "pages/risk/_id/index" */))
const _6e06ac8e = () => interopDefault(import('../pages/suggestion/_id/index.vue' /* webpackChunkName: "pages/suggestion/_id/index" */))
const _d1b4af0e = () => interopDefault(import('../pages/unit/_id/index.vue' /* webpackChunkName: "pages/unit/_id/index" */))
const _35d8f23b = () => interopDefault(import('../pages/report/_id/_procedure/a/_appendix/index.vue' /* webpackChunkName: "pages/report/_id/_procedure/a/_appendix/index" */))
const _c2693222 = () => interopDefault(import('../pages/report/_id/_procedure/_content/index.vue' /* webpackChunkName: "pages/report/_id/_procedure/_content/index" */))
const _20eb9fca = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _08c7ac8e,
    name: "account"
  }, {
    path: "/action",
    component: _7cea1a00,
    name: "action"
  }, {
    path: "/archive",
    component: _5c1a0a0e,
    name: "archive"
  }, {
    path: "/audit",
    component: _c6b81bb2,
    name: "audit"
  }, {
    path: "/deviation",
    component: _a03c5a66,
    name: "deviation"
  }, {
    path: "/it",
    component: _8bb9f996,
    name: "it"
  }, {
    path: "/login",
    component: _7c0bac35,
    name: "login"
  }, {
    path: "/logout",
    component: _8bccc8de,
    name: "logout"
  }, {
    path: "/offline",
    component: _63297c6c,
    name: "offline"
  }, {
    path: "/onboarding",
    component: _613aa125,
    name: "onboarding"
  }, {
    path: "/organization",
    component: _52dcab06,
    name: "organization"
  }, {
    path: "/register",
    component: _694a2f6d,
    name: "register"
  }, {
    path: "/report",
    component: _13fda47e,
    name: "report"
  }, {
    path: "/risk",
    component: _4d9b3419,
    name: "risk"
  }, {
    path: "/search",
    component: _16f8b3f2,
    name: "search"
  }, {
    path: "/styles",
    component: _3d8e9a2e,
    name: "styles"
  }, {
    path: "/suggestion",
    component: _08e725ce,
    name: "suggestion"
  }, {
    path: "/task",
    component: _c582d962,
    name: "task"
  }, {
    path: "/unit",
    component: _ea44f6e4,
    name: "unit"
  }, {
    path: "/verify",
    component: _53124583,
    name: "verify"
  }, {
    path: "/account/organizations",
    component: _5b2fe487,
    name: "account-organizations"
  }, {
    path: "/account/password",
    component: _32df2a04,
    name: "account-password"
  }, {
    path: "/account/security",
    component: _413f0c29,
    name: "account-security"
  }, {
    path: "/audit/plan",
    component: _44c2d167,
    name: "audit-plan"
  }, {
    path: "/organization/billing",
    component: _6d31eee6,
    name: "organization-billing"
  }, {
    path: "/organization/companies",
    component: _df3b14f4,
    name: "organization-companies"
  }, {
    path: "/organization/events",
    component: _0d72d97e,
    name: "organization-events"
  }, {
    path: "/organization/groups",
    component: _1f37fcb9,
    name: "organization-groups"
  }, {
    path: "/organization/job",
    component: _529798a8,
    name: "organization-job"
  }, {
    path: "/organization/members",
    component: _8987d2f8,
    name: "organization-members"
  }, {
    path: "/organization/subscription",
    component: _690bfc7c,
    name: "organization-subscription"
  }, {
    path: "/password/activate",
    component: _ebe0e09a,
    name: "password-activate"
  }, {
    path: "/password/forgot",
    component: _5eef91ba,
    name: "password-forgot"
  }, {
    path: "/password/reset",
    component: _5e4a8865,
    name: "password-reset"
  }, {
    path: "/task/group",
    component: _8e190802,
    name: "task-group"
  }, {
    path: "/action/:id",
    component: _789ae42a,
    name: "action-id"
  }, {
    path: "/audit/:id",
    component: _5e59a192,
    name: "audit-id"
  }, {
    path: "/deviation/:id",
    component: _2e6a8190,
    name: "deviation-id"
  }, {
    path: "/it/:id",
    component: _d6a508c0,
    name: "it-id"
  }, {
    path: "/report/:id",
    component: _52545069,
    name: "report-id"
  }, {
    path: "/risk/:id",
    component: _5d1a9a84,
    name: "risk-id"
  }, {
    path: "/suggestion/:id",
    component: _6e06ac8e,
    name: "suggestion-id"
  }, {
    path: "/unit/:id",
    component: _d1b4af0e,
    name: "unit-id"
  }, {
    path: "/report/:id/:procedure/a/:appendix",
    component: _35d8f23b,
    name: "report-id-procedure-a-appendix"
  }, {
    path: "/report/:id/:procedure/:content",
    component: _c2693222,
    name: "report-id-procedure-content"
  }, {
    path: "/",
    component: _20eb9fca,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
